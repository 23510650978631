












import { I18n } from '@/i18n/i18n';
import { ErrorResponse } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Error extends Vue {
  @Prop() readonly error!: ErrorResponse;

  i18n = new I18n();

  get translatedError() {
    return this.i18n.translateError(this.error);
  }
}
